import { createGlobalStyle } from 'styled-components';
import { PREVENT_SCROLL_CLASS_NAME } from '../config/consts';
import { Header } from 'app/Components/Header/Authed/styles';
import { ComparisonTableRow } from 'app/Pages/Quote/Profile/body/Quote/partials/ComparisonTable/styles';
import { PageQuote } from 'app/Pages/Quote/Profile/styles';
import {
  ComparisonTableBackButton,
  HeadContainer,
  OffersSortOrderContainer
} from 'app/Pages/Quote/Profile/body/Quote/styles';
import { ControlsButton } from '../app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';

export const GlobalStyles = createGlobalStyle`
  /* Reset */
  
  /* https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Remove default padding */
  ul[class] {
    padding: 0;
  }
  
  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }
  
  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-print-color-adjust: exact;
    
    @media print {
      ${Header},
      ${ComparisonTableBackButton},
      ${HeadContainer},
      ${OffersSortOrderContainer},
      ${ControlsButton},
      ${PageQuote} .sidebar,
      ${ComparisonTableRow}:last-child {
          display: none;
      }
    }
  }
  
  /* Remove list styles on ul elements with a class attribute */
  ul[class] {
    list-style: none;
  }
  
  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  
  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }
  
  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }
  
  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /* Helpers */

  .${PREVENT_SCROLL_CLASS_NAME} {
    overflow: hidden !important;
  }
`;
